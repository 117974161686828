.menu-items {
    text-align: left;
    position: absolute;
    padding: 80px;
    margin-top: 0;
    margin-left: 0;
    top: 0;
    left: 0;
    list-style: none;
    opacity: 0;
    font-size: 25px;
    background-color: #1f76bd;
    width: 70%;
    height: 100vh;
    transform: translateX(-100%);
    transition: transform 100ms ease-in-out, opacity 200ms;
}
@media  screen and (max-width: 600px) {
    .menu-items{
        width: 100%;
        padding: 80px 30px;
    }
}

.menu-items li{
    color: white;
    padding: 0.5rem;
}

.menu-items li a{
    color: white;
    text-decoration: none;
}

.menu-items li a:hover{
    color: rgb(174, 174, 174);
}

.check {
    position: absolute;
    top: 20px;
    left: 30px;
    height: 40px;
    width: 30px;
    opacity: 0;
    z-index: 3;
}

.check:hover {
    cursor: pointer;
}

.check:checked~.menu-items {
    display: block;
    transform: translateX(0%);
    opacity: 1;
}

.ham-menu {
    height: 40px;
    width: 30px;
    position: absolute;
    top: 20px;
    left: 30px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-radius: 5px;
}

.ham-menu .line {
    background-color: white;
    border-radius: 10px;
    width: 40px;
    height: 5px;
}

.ham-menu .line1 {
    transform-origin: 0 0;
    transition: transform 100ms ease-in-out;
}

.ham-menu .line3 {
    transform-origin: 0 100%;
    transition: transform 100ms ease-in-out;
}

.check:checked~.ham-menu .line1 {
    display: block;
    transform: rotate(45deg);
}

.check:checked~.ham-menu .line2 {
    opacity: 0;
}

.check:checked~.ham-menu .line3 {
    display: block;
    transform: rotate(-45deg);
}
.navbar{
    width: 100%;
    height: 80px;
    background-color: #1f76bd;
    box-shadow: 5px 5px 5px grey;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999999999;
}

.nav{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    height: 100%;

}

.nav li{
    margin-right: 20px;
    font-size: 16px;
    color: white;
    cursor: pointer;
    font-weight: 600;
    text-decoration: none;
}

.nav li:hover{
    color: #c6c9cb;
}

.donate{
    padding: 10px;
    background-color: #900707;
    border-radius: 10px;
    color: white !important;
    border: 1px solid transparent;
}
.donate:hover{
    background-color: #e11d1d;
    cursor: pointer;
}
.section{
    width: 100%;
}
.section-block{
    width: 90%;
    margin: 0 auto;
    padding: 50px 0;
}
.title{
    font-size: 28px;
    text-align: left;
    margin-bottom: 50px;
}

.description-block{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.description-block img{
    width: 45%;
    height: 400px;
    object-fit: scale-down;
}
.description-block-div{
    width: 70%;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    border: 2px solid #1f76bd;
    border-top: 40px solid #1f76bd;
    padding: 10px;
    border-radius: 10px;
}

.left{

}

.right{
    flex-direction: row-reverse;
}

@media screen and (max-width: 1279px){
    .description-block{
        flex-direction: column;
    }
    .description-block img{
        width: 400px;
        margin: 40px auto;
        object-fit: contain;
    }
    .description-block-div{
        width: 100%;
    }
}
@media screen and (max-width: 767px){
    .description-block img{
        width: 100%;
    }
}